import homeCss from '../styles/home.css?url';

import NavBar from '~/components/home/NavBar';
import { Image } from '@nextui-org/react';

export const links = () => [{ rel: 'stylesheet', href: homeCss }];



export default function Index() {
  return (
    <div>
      <NavBar />
      <div className="flex items-center justify-center">
        <Image
          width={1080}
          src="https://lacasatour.b-cdn.net/images/1702995443740_571sbarfielddrmarcoislandfl341453.jpg?class=wmark"
        />
      </div>
     
      
    </div>
  );
}
